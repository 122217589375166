<template>
  <div
    class="stag"
    :class="{'is-focused': isFocused}"
  >
    <div class="stag__field">
      <div class="stag-field">
        <div
          v-for="(tag, index) in value"
          :key="index"
          class="stag-field__tag"
        >
          <div
            class="stag-item"
            @click.prevent="onTag(index)"
          >
            {{ tag }}
            <div class="stag-item__icon">
              <feather-icon
                icon="XIcon"
              />
            </div>
          </div>
        </div>
        <div class="stag-field__input">
          <input
            ref="input"
            v-model="query"
            type="text"
            :readonly="readonly"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onEnter"
            @keyup.delete="onDelete"
          >
        </div>
      </div>
    </div>
    <small
      v-if="isPrompt"
      class="text-warning"
    >
      {{ textPrompt }}
    </small>
  </div>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    emails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      isFocused: false,
      deleteFlag: false,
      isPrompt: false,
      textPrompt: '',
    }
  },
  methods: {
    isEmail(elementValue) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return emailPattern.test(elementValue)
    },

    onEnter() {
      if (this.emails && !this.isEmail(this.query)) {
        this.showPrompt('Введите корректный email')
        return false
      }

      this.closePrompt()

      if (this.query) {
        this.$emit('input', [
          ...this.value,
          this.query,
        ])
      }

      this.query = ''

      return true
    },

    onDelete() {
      const caretPosition = this.$refs.input.selectionStart

      if (caretPosition === 0 && this.deleteFlag && this.value) {
        const tags = this.value.slice(0)
        tags.splice(-1, 1)
        this.$emit('input', [
          ...tags,
        ])
      }

      this.deleteFlag = caretPosition === 0 && !this.deleteFlag
    },

    onTag(index) {
      const tags = this.value.slice(0)
      tags.splice(index, 1)
      this.$emit('input', [
        ...tags,
      ])
    },

    onFocus() {
      this.isFocused = true
      this.closePrompt()
    },

    onBlur() {
      this.isFocused = false

      if (this.query) {
        this.showPrompt('Нажмите enter для ввода')
      }
    },

    showPrompt(text) {
      this.textPrompt = text
      this.isPrompt = true
    },

    closePrompt() {
      this.isPrompt = false
    },
  },
}
</script>

<style lang="scss">
  .stag {
    &__field {
      padding: 0.438rem 1rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      display: block;
      width: 100%;
      min-height: 2.714rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &.is-focused {
      .stag__field {
        box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
        background-color: #fff;
        border-color: #7367F0;
      }
    }
  }

  .stag-field {
    display: flex;
    flex-wrap: wrap;

    &__tag {
      margin: 2px;
    }

    &__input {
      flex: 1;
      min-width: 100px;
      height: 24px;
      margin: 2px 0 2px 2px;
      position: relative;

      input {
        display: block;
        width: 100%;
        height: 24px;
        border: none;
        background: none;
        outline: none;
        position: absolute;
        top: 0;
        bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.45;
        color: #6e6b7b;
      }
    }
  }

  .stag-item {
    display: flex;
    padding: 3px 5px;
    background: #7367F0;
    border-radius: 3px;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    white-space: nowrap;

    &__icon {
      margin-left: 3px;
    }

    &:hover {
      background: #6056D0;
    }
  }
</style>
