<template>
  <div class="add-time">
    <b-form-input
      v-b-toggle="'add-time'"
      :value="textDays"
      readonly
    />

    <b-collapse
      id="add-time"
      v-model="visible"
    >
      <div class="mt-1 p-1 bg-light">
        <div class="add-time__btns">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="fastSelect(['Пн', 'Вт', 'Ср', 'Чт', 'Пт'])"
          >
            Будни
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="fastSelect(['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вск'])"
          >
            Ежедневно
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="fastSelect(['Круглосуточно'])"
          >
            Круглосуточно
          </b-button>
        </div>
        <div>
          <div
            v-for="(day, index) in days"
            :key="index"
            class="row"
          >
            <div class="col-5 col-md-2">
              <b-form-group class="mb-0">
                <b-form-checkbox v-model="day.active">
                  {{ day.text }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="col-7 col-md-4  pl-md-0">
              <b-form-group class="mb-0">
                <div class="d-flex align-items-center">
                  <div>c</div>
                  <b-form-timepicker
                    v-model="day.working_begin"
                    size="sm"
                    minutes-step="10"
                    hide-header
                    :disabled="day.active ? false : true"
                  />
                  <div>до</div>
                  <b-form-timepicker
                    v-model="day.working_end"
                    size="sm"
                    minutes-step="10"
                    hide-header
                    :disabled="day.active ? false : true"
                  />
                </div>
              </b-form-group>
            </div>
            <div class="col-5 col-md-2 px-md-0">
              <b-form-group class="mb-0">
                <b-form-checkbox
                  v-model="day.break"
                  :disabled="day.active ? false : true"
                >
                  Перерыв
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div class="col-7 col-md-4">
              <b-form-group class="mb-0">
                <div class="d-flex align-items-center">
                  <div>c</div>
                  <b-form-timepicker
                    v-model="day.break_begin"
                    size="sm"
                    minutes-step="10"
                    hide-header
                    :disabled="day.break ? false : true"
                  />
                  <div>до</div>
                  <b-form-timepicker
                    v-model="day.break_end"
                    size="sm"
                    minutes-step="10"
                    hide-header
                    :disabled="day.break ? false : true"
                  />
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BFormGroup, BButton, VBToggle, BCollapse, BFormCheckbox, BFormTimepicker, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BButton,
    BCollapse,
    BFormCheckbox,
    BFormTimepicker,
    BFormInput,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      textDays: '',
      days: [
        {
          active: false,
          text: 'Пн',
          value: 'monday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Вт',
          value: 'tuesday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Ср',
          value: 'wednesday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Чт',
          value: 'thursday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Пт',
          value: 'friday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Сб',
          value: 'saturday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
        {
          active: false,
          text: 'Вск',
          value: 'sunday',
          working_begin: '09:00:00',
          working_end: '18:00:00',
          break: false,
          break_begin: '09:00:00',
          break_end: '18:00:00',
        },
      ],
    }
  },
  computed: {
    textTime() {
      const groupedByTime = {}
      this.days.forEach(day => {
        if (day.active) {
          const timeKey = `${day.working_begin.slice(0, -3)}-${day.working_end.slice(0, -3)}-${day.break}-${day.break_begin.slice(0, -3)}-${day.break_end.slice(0, -3)}`
          if (!groupedByTime[timeKey]) groupedByTime[timeKey] = []
          groupedByTime[timeKey].push(day.text)
        }
      })
      const result = []
      Object.keys(groupedByTime).forEach(timeKey => {
        const daysGroup = groupedByTime[timeKey]
        let currentRange = []
        const [workingBegin, workingEnd, breakActive, breakBegin, breakEnd] = timeKey.split('-')
        const timeText = `${workingBegin} до ${workingEnd}`
        const breakText = breakActive === 'true' ? `, перерыв с ${breakBegin} до ${breakEnd}` : ''
        const groupResult = []
        daysGroup.forEach((day, index) => {
          const currentIndex = this.days.findIndex(d => d.text === day)
          if (
            currentRange.length === 0
            || this.days.findIndex(d => d.text === daysGroup[index - 1]) === currentIndex - 1
          ) {
            currentRange.push(day)
          } else {
            groupResult.push(
              currentRange.length > 1
                ? `${currentRange[0]}-${currentRange[currentRange.length - 1]}`
                : currentRange[0],
            )
            currentRange = [day]
          }
        })
        if (currentRange.length > 0) {
          groupResult.push(
            currentRange.length > 1
              ? `${currentRange[0]}-${currentRange[currentRange.length - 1]}`
              : currentRange[0],
          )
        }
        result.push(`${groupResult.join(', ')} с ${timeText}${breakText}`)
      })
      return result.join(', ')
    },
  },
  watch: {
    textTime() {
      const days = this.days.map(i => ({
        day: i.value,
        working_hours: i.active ? {
          begin: i.working_begin.slice(0, -3),
          end: i.working_end.slice(0, -3),
        } : null,
        break: i.break ? {
          begin: i.break_begin.slice(0, -3),
          end: i.break_end.slice(0, -3),
        } : null,
      }))
      this.$emit('input', days)
    },
  },
  mounted() {
    setTimeout(() => {
      this.textDays = this.text
      this.value.forEach(a => {
        this.days.forEach(b => {
          if (b.value === a.day) {
            if (a.working_hours) {
              b.active = true
              b.working_begin = `${a.working_hours.begin}:00`
              b.working_end = `${a.working_hours.end}:00`
            }
            if (a.break) {
              b.break = true
              b.break_begin = `${a.break.begin}:00`
              b.break_end = `${a.break.end}:00`
            }
          }
        })
      })
    }, 1000)
  },
  methods: {
    fastSelect(selectedDays) {
      if (selectedDays[0] === 'Круглосуточно') {
        this.days.forEach(day => {
          day.active = true
          day.working_begin = '00:00:00'
          day.working_end = '23:59:59'
        })
      } else {
        this.days.forEach(day => {
          if (selectedDays.includes(day.text)) {
            day.active = true
            day.working_begin = '09:00:00'
            day.working_end = '18:00:00'
          } else day.active = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
.add-time {
  &__btns {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &:first-child {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-top: 8px;
    }
  }

  &__days {
    margin-top: 16px;

    .custom-control {
      padding: 20px 0 0;
      cursor: pointer;

      * {
        cursor: pointer;
      }
    }
  }

  .row {
    align-items: center;

    // Костыль
    @media (min-width: 1199px) and (max-width: 1780px) {
      margin: 0;

      & > div {
        min-width: 50% !important;
        max-width: 50% !important;
        padding: 0 !important;
      }
    }
    //

    &:not(:last-child) {
      border-bottom: 0.1rem solid #efefef;
    }
  }

  .form-control[readonly] {
    background: #fff;
  }

  .custom-control-label {
    &::before, &:after {
      cursor: pointer;
    }
  }

  .b-form-timepicker {
    width: 70px;
    margin: 5px;

    & > .btn-sm {
      padding: 0 !important;

      svg {
        display: none;
      }
    }

    .form-control {
      padding: 0;
      line-height: 30px;
      text-align: center;
    }

    .dropdown-menu {
      min-width: auto;
    }
  }

  .b-time {
    display: block !important;
    min-width: auto !important;

    &-header, &-footer {
      display: none;
    }
  }

  .b-form-spinbutton output > bdi {
    display: block;
    min-width: 2.25em;
    height: 1.45em;
    line-height: 1.45em;
  }
}
</style>
