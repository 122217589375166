<template>
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="row mx-0 mb-1 d-none d-lg-flex">
        <div class="col-6 pl-2">
          <b>Страница</b>
        </div>
        <div class="col-4">
          <b>URL страницы</b>
        </div>
        <div class="col-2 text-right">
          <b>Действия</b>
        </div>
      </div>
      <div class="list-group">
        <div
          v-for="(page, index) in staticPages"
          :key="index"
          class="list-group-item"
        >
          <div class="row align-items-center">
            <div class="col-6">
              {{ page.title }}
            </div>
            <div class="col-4">
              {{ page.link }}
            </div>
            <div class="col-2 text-right">
              <b-button
                v-b-tooltip.hover.top="'Редактировать'"
                size="sm"
                variant="link"
                class="btn-icon"
                @click.prevent="openEditPopup(page.key)"
              >
                <feather-icon
                  icon="Edit3Icon"
                  size="18"
                />
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        v-if="editPage"
        id="edit-page-popup"
        :visible="showEditPopup"
        size="lg"
        ok-only
        ok-title="Сохранить"
        :title="`Редактирование страницы «${popupTitle}»`"
        @ok="clickOnSave"
        @hidden="showEditPopup = false"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <div
            v-if="editPage.type === 'brand_page'"
            class="mb-2"
          >
            Важно: <br>
            В каждом поле прописать переменную: <br>
            {{ '{' }}{{ '{' }} brand {{ '}' }}{{ '}' }} - название бренда латиницей <br>
            или <br>
            {{ '{' }}{{ '{' }} brand_rus {{ '}' }}{{ '}' }} - название бренда кириллицей
          </div>
          <b-form-group
            label="Заголовок H1"
            label-for="h1"
          >
            <b-form-input
              id="h1"
              v-model="editPage.h1"
              name="h1"
            />
            <small
              v-show="errors.has('h1')"
              class="text-danger"
            >
              {{ errors.first('h1') }}
            </small>
          </b-form-group>
          <b-form-group
            label="Meta Title"
            label-for="meta_title"
          >
            <b-form-input
              id="meta_title"
              v-model="editPage.meta_title"
              name="meta_title"
            />
            <small
              v-show="errors.has('meta_title')"
              class="text-danger"
            >
              {{ errors.first('meta_title') }}
            </small>
          </b-form-group>
          <b-form-group
            label="Meta Description"
            label-for="meta_description"
          >
            <b-form-textarea
              id="meta_description"
              v-model="editPage.meta_description"
              name="meta_description"
              rows="4"
              no-resize
            />
            <small
              v-show="errors.has('meta_description')"
              class="text-danger"
            >
              {{ errors.first('meta_description') }}
            </small>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormInput, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Static from '@/api/http/models/static/Static'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      staticPages: [
        {
          title: 'Главная страница',
          link: '/',
          key: 'main_page',
        },
        {
          title: 'Страница услуг',
          link: '/services',
          key: 'services_page',
        },
        {
          title: 'Страница брендов',
          link: '/brands',
          key: 'brands_page',
        },
        {
          title: 'Брендовые страницы',
          link: ' /brands/...',
          key: 'brand_page',
        },
        {
          title: 'Акции',
          link: '/promo',
          key: 'promos_page',
        },
        {
          title: 'Контакты',
          link: '/contacts',
          key: 'contacts_page',
        },
        {
          title: 'Отзывы',
          link: '/reviews',
          key: 'reviews_page',
        },
        {
          title: 'Правовая информация',
          link: '/policy',
          key: 'policy_page',
        },
      ],
      pagesMeta: null,
      showEditPopup: false,
      editPage: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
    popupTitle() {
      return this.staticPages.find(i => i.key === this.editPage.type).title
    },
  },
  async created() {
    await this.fetchPages()
  },
  methods: {
    async fetchPages() {
      const response = await Static.getAll(this.currentSite.slug)
      this.pagesMeta = response.data
    },
    openEditPopup(key) {
      this.editPage = this.pagesMeta.find(i => i.type === key)
      this.showEditPopup = true
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.editPage = null
    },
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()
      if (validate) {
        this.pagesMeta = this.pagesMeta.map(page => {
          if (page.type === this.editPage.type) return this.editPage
          return page
        })
        const response = await Static.update(this.currentSite.slug, {
          items: this.pagesMeta,
        })

        if (response.data.status === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Данные страницы обновлены',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.showEditPopup = false
          })
        }

        if (response.data.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении данных страницы',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
